export const enum pipedriveValue {
  GAQ_INDIVIDUAL = 606,
  GAQ_FAMILY = 607,
  GAQ_TEAM = 608,
  GAQ_LIFE = 619,
  EQT_APPLY = 609,
  EQT_COMPARE = 610,
  EQT_RECEIVE_DETAILS = 611,
  EQT_NONHK = 612,
  BOOK_A_CALL = 616,
  ARTICLE_FORM = 617,
  //new
  EQT_LOG = 666,
  //where find us
  SOURCE_ALEA = 664,
  //form position
  POSITION_HEADER = 656,
  POSITION_FOOTER = 657,
  POSITION_CONTENT = 658,
  POSITION_COLUMN = 659,
  POSITION_APPLY = 660,
  POSITION_COMPARE = 661,
  POSITION_RECEIVE_DETAILS = 662,
  POSITION_NONHK = 663,
  //product
  PRODUCT_TEAM = 665,
}
