import "./get-a-quote.scss"

import React from "react"

import { StaticImage } from "gatsby-plugin-image"

const Slogan = props => {
  const { title } = props
  return (
    <>
      <div className="sloganGetAQuote ming container-fluid text-center position-absolute">
        <StaticImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          src="../../../images/get-a-quote/left-full.webp"
          className="leftFull"
          alt=""
        />
        <StaticImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          src="../../../images/get-a-quote/left-empty.webp"
          className="leftEmpty"
          alt=""
        />

        <StaticImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          src="../../../images/get-a-quote/right-full.webp"
          className="rightFull"
          alt=""
        />
        <StaticImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          src="../../../images/get-a-quote/right-empty.webp"
          className="rightEmpty"
          alt=""
        />
        <h1 className=" title">{title}</h1>
      </div>
    </>
  )
}
export default Slogan
