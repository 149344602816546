import React from "react"
import { CustomMarkdown } from "../../../plugins/sanitizehtml"

const confirmation = ({ context }) => {
  return (
    <div className="confirmation">
      <div className="title">
        <h3>{context.subtitle}</h3>
      </div>
      <div className="content black-text">
        <CustomMarkdown html={context.content} toSanitize={false} />
      </div>
    </div>
  )
}
export default confirmation
